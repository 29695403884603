<template>
  <div  style="max-width: 500px; margin: auto">
    <div class="accordion" role="tablist">
      <h3>Protocolos SAC</h3>
      <b-row class="m-0 p-0">
        <b-col cols="4 text-center mb-3">
          DE:
        </b-col>
        <b-col cols="8 text-center mb-3">
          <input type="date" name="" id="" v-model="from">
        </b-col>
        <b-col cols="4 text-center mb-3">
          ATÉ:
        </b-col>
        <b-col cols="8 text-center mb-3">
          <input type="date" name="" id="" v-model="to">
        </b-col>
        <b-col cols="12" class="text-center mb-3">
          <b-button @click="searchList">Consultar</b-button>
        </b-col>
        <b-col cols="12" class="text-center mb-3">
          {{retorno}}
        </b-col>
      </b-row>

      <b-card no-body class="mb-1" v-for="(e, i) in list" :key="'itemProtocolo'+i" >
        <b-card-header header-tag="header" class="p-1 pointer" role="tab" v-b-toggle="'accordion-'+i">
          <div class="text-center my-2 mx-1 " block variant="info">
            <b-row>
              <b-col class="text-nowrap">
                {{formatDate(e.dt_hr_protocolo)}}
                <br>
                {{formatCurrency(e.valorReais)}}
              </b-col>
              <b-col class="text-nowrap">{{e.protocolo}}</b-col>
              <b-col class="text-nowrap">
                {{processStatus(e.status)}} 
                <span class="material-icons text-info" v-if="e.status == '0'" >pending</span>
                <span class="material-icons text-success" v-else-if="e.status == '1'" >check_circle</span>
                <span class="material-icons text-danger" v-else-if="e.status == '2'" >cancel</span>
              </b-col>
            </b-row>
          </div>
        </b-card-header>
        <b-collapse :id="'accordion-'+i" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col cols="12" class="bg-th">PROTOCOLO</b-col><b-col cols="12" class="text-right mb-2">{{e.protocolo}}</b-col>
                <b-col cols="12" class="bg-th">PLACA</b-col><b-col cols="12" class="text-right mb-2">{{e.placa}}</b-col>
                <b-col cols="12" class="bg-th">CARTÃO</b-col><b-col cols="12" class="text-right mb-2">{{e.num_cartao}}</b-col>
                <b-col cols="12" class="bg-th">CONDUTOR</b-col><b-col cols="12" class="text-right mb-2">{{e.nome_condutor}}</b-col>
                <b-col cols="12" class="bg-th">COD.CONDUTOR</b-col><b-col cols="12" class="text-right mb-2">{{e.cod_condutor}}</b-col>
                <b-col cols="12" class="bg-th">PRODUTO</b-col><b-col cols="12" class="text-right mb-2">{{e.produto}}</b-col>
                <b-col cols="12" class="bg-th">QUANTIDADE</b-col><b-col cols="12" class="text-right mb-2">{{e.qtd_litros}}</b-col>
                <b-col cols="12" class="bg-th">VALOR</b-col><b-col cols="12" class="text-right mb-2">{{formatCurrency(e.valorReais)}}</b-col>
                <b-col cols="12" class="bg-th">DATA</b-col><b-col cols="12" class="text-right mb-2">{{formatDate(e.dt_hr)}}</b-col>
                <b-col cols="12" class="bg-th">COMPROVANTE</b-col><b-col cols="12" class="text-right mb-2">
                  <a :href="'https://www.sistemagtf.com.br/vue/scripts/venda_0800_adm.php?func=loadDoc&file=' + e.documento" target="_blank" >BAIXAR COMPROVANTE</a>
                </b-col>
                <b-col cols="12" class="bg-th">OBSERVAÇÕES</b-col><b-col cols="12" class="text-right mb-2">{{e.observacao}}</b-col>
                <b-col cols="12" class="bg-th">RESPOSTA DO SAC</b-col><b-col cols="12" class="text-right mb-2">{{e.feedback}}</b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
var today = new Date()
var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1,0).getDate()

export default {
  name: 'listProtocolos',
  data() {
    return{
      from: new Date().getFullYear() + '-' + (new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)) + '-' + '01',
      to: new Date().getFullYear() + '-' + (new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)) + '-' + lastDayOfMonth,
      list: [],
      retorno: ' -- '
    }
  },
  methods: {
    searchList() {
      var formdata = new FormData()
      formdata.append('to', 'listProtocolos')
      formdata.append('token', this.$store.state.AuthenticationToken)
      formdata.append('dateFrom', this.from)
      formdata.append('dateTo', this.to)
      this.list = []
      this.retorno = 'Carregando'
      axios.post(
        this.$store.state.server_path+'pos.php',
        formdata,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      ).then( response => {
        console.log(response.data)
        if (Object.prototype.hasOwnProperty.call(response.data, 'status')) {
          if (response.data.status) {
            this.list = response.data.obj
            if (Object.keys(response.data.obj).length == 0) {
              this.retorno = 'Nenhum Resultado Encontrado'
            } else {
              this.retorno = 'RESULTADOS ENTRE ' + this.from.split('-')[2] + '/' + this.from.split('-')[1] + '/' + this.from.split('-')[0] + ' À ' + this.to.split('-')[2] + '/' + this.to.split('-')[1] + '/' + this.to.split('-')[0]
            }
          } else {
            this.retorno = response.data.msg
          }
        } else {
          this.retorno = 'ERRO -- ' . response.data
        }

      })
    },
    formatDate(date) {
      try {
        var day = new Date(date)  
      } catch (error) {
        return error
      }
      return (day.getDate() > 9 ? day.getDate() : '0' + day.getDate())  + '/' + (day.getMonth() + 1 > 9 ? day.getMonth() + 1 : '0' + (day.getMonth() + 1)) + '/' + day.getFullYear()
    },
    formatCurrency(value) {
      try {
        var val = parseFloat(value)
      } catch (error) {
        return error
      }
      return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    processStatus(status) {
      switch (status) {
        case "0":
          return 'EM ANALISE'
        case "1":
          return 'APROVADO'
        case "2":
          return 'REPROVADO'
        default:
          return '?'
      }
    }
  },
  mounted: function () {
    this.searchList()
  }
}
</script>
<style scoped>
.bg-th{
  background-color: #ddd;
}
.pointer {
  cursor: pointer;
}
.material-icons{
    display: inline-flex;
    vertical-align: top;
}
</style>
