<template>
    <b-modal 
      id="ComprovanteModal" 
      :title="TitleModal" 
      hide-footer 
      hide-header 
      centered 
      no-close-on-esc 
      no-close-on-backdrop 
      :body-bg-variant="bodyBgVariant" 
      :body-text-variant="bodyTextVariant" 
      >
        <h5>{{TitleModal}}</h5>
        <div class="my-4 text-center">
          <div id="recibo_bg">
            <div id="recibo" v-html="msg"></div>
          </div>
            
        </div>
        <b-row>
          <b-col cols="12" class="my-2" v-if="isAndroidPos">
            <b-button block @click="printPOS" >Imprimir POS</b-button> 
          </b-col>
          <b-col cols="12" class="my-2">
            <b-button block @click="print"  v-if="!isAndroidPos" >Imprimir</b-button> 
          </b-col>
          <b-col cols="12" v-if="true">
            <b-button block @click="downloadRecibo()" >Salvar</b-button> 
          </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col cols="6" v-if="btnTryAgain">
              <b-button block @click="$bvModal.hide('ComprovanteModal')">Tentar Nov.</b-button>
            </b-col>
            <b-col cols="6" >
              <b-button block @click="home()">Sair</b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import html2canvas from "html2canvas";
import { setTimeout } from 'timers';
// const toText = (html) => {
//   html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
//   html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
//   html = html.replace(/<\/div>/ig, '\n');
//   html = html.replace(/<\/li>/ig, '\n');
//   html = html.replace(/<li>/ig, '  *  ');
//   html = html.replace(/<\/ul>/ig, '\n');
//   html = html.replace(/<\/p>/ig, '\n');
//   html = html.replace(/<br\s*[\/]?>/gi, "\n");
//   html = html.replace(/<[^>]+>/ig, '');
//   return html;
// }
export default {
  name:'ComprovanteModal',
  data(){
    return {
    }
  },
  computed: {
    isAndroidPos: function () {
      return typeof window.Android !== 'undefined'
    }
  },
  props: {
    bodyBgVariant: String,
    bodyTextVariant: String,
    msg : {
        default : "Erro - Retorno vazio.",
        type : String
    },
    TitleModal : {
        default : 'Resposta: ',
        type : String
    },
    btnSave : {
        default : false,
        type : Boolean
    },
    btnTryAgain : {
        default : false, 
        type : Boolean
    },
    autoPrint: {
        default : false, 
        type : Boolean
    }
  },
  methods : {
   BtPrint: function (prn) {
      // var S = "#Intent;scheme=rawbt;";
      // var P =  "package=ru.a402d.rawbtprinter;end;";
      // var textEncoded = encodeURI(prn);
      // window.location.href="intent:"+textEncoded+S+P;

      const string = prn.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[&\\#+~%'"*?{}]/g, '');

      window.Android.print("\n\n"+string+"\n\n\n\n")
    },
    printPOS: function () {
      this.BtPrint(document.getElementById('recibo').innerText)
    },
    print: function () {
      window.print()
    },
    home : function () {
        this.$router.push({ path: '/Menu' })
    },
    downloadRecibo : function () {
        let options = {
          heigth : document.querySelector('#recibo').clientHeight , 
          width : document.querySelector('#recibo').clientWidth,
          x : 0,
          y : 0 
        }
        html2canvas(document.querySelector("#recibo",options)).then( canvas => {

            // -------------PREI AKI

            /// create an "off-screen" anchor tag
            var lnk = document.createElement('a'), e;
            lnk.target = '_blank'
        
            /// the key here is to set the download attribute of the a tag
            lnk.download = 'Ticket.jpeg';
        
            /// convert canvas content to data-uri for link. When download
            /// attribute is set the content pointed to by link will be
            /// pushed as "download" in HTML5 capable browsers
            // lnk.href = canvas.toDataURL("image/png;base64");
            lnk.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");

            /// create a "fake" click-event to trigger the download
            if (document.createEvent) {
            e = document.createEvent("MouseEvents");
            e.initMouseEvent("click", true, true, window,
                            0, 0, 0, 0, 0, false, false, false,
                            false, 0, null);
        
            lnk.dispatchEvent(e);
            } else if (lnk.fireEvent) {
            lnk.fireEvent("onclick");
            }
            window.console.log(lnk);
        });
    }
  },

  watch: {
    msg: function (val) {
      console.log('msg Watcher', this.autoPrint, window.Android)
      if (val) {
        if (this.autoPrint) {
          if (window.Android) {
            setTimeout(this.printPOS, 750)
          }
        }
      }
    }
  },
  mounted: function() {
    // this.onInit();

  }
}
</script>
<style >
    #recibo {
        white-space: break-spaces;
        padding-bottom: 5em;
        text-align: left;
        font-family: monospace;
        color: black;
        padding: 1em;
        /* min-height: 100px; */
        /* width: 100vw; */
        /* height: 100vh; */
    }
    #recibo_bg{
      background-color: #fdffb8;
    }
</style>