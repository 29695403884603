import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'


// import { VueReCaptcha } from 'vue-recaptcha-v3'
// import WebCam from "vue-web-cam";
import VueQrcodeReader from "vue-qrcode-reader";
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import App from './App.vue'
import Login from './components/Login.vue'
import Painel from './components/Painel.vue'
import Services from './components/Services.vue'
import ServiceMenu from './components/ServiceMenu.vue'
import ServiceForm from './components/ServiceForm.vue'
import ServiceComponent from './components/ServiceComponent.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


// imports css 
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles/custom.css'

import vueMask from 'vue-jquery-mask';


// import js
import appFunction from './js/functions'
// import './quasar'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTheMask)
Vue.use(vueMask)
Vue.use(appFunction)
// Vue.use(WebCam)
Vue.use(VueQrcodeReader)
Vue.use(money, {precision: 4})



var serve_patch
if (window.location.href.match(/localhost/) == null && window.location.href.match(/\d+\.\d+.\d+.\d+:\d+/) == null) {
  serve_patch = '/server/'
} else {
  serve_patch = 'https://pos.sistemagtf.com.br/server/'
}
var jsonFileName = 'POS_JSON.php?v=2.2'
const store = new Vuex.Store(
  {
    state : {
      themeMode : {mode : 'light' , text : 'black'}, //ThemeMode pode ser Daark ou light - depende da refra de preferencia do navegador
      server_path : serve_patch,
      jsonFileName : jsonFileName,
      Authenticated : false ,
      AuthenticationToken : '',
      painel_mes : '',
      Data : {}
    },
    mutations : {
      setThemeMode(state, mode){
        switch (mode) {
          case 'light':
            mode = {mode : 'light' , text : 'black'}
            break;
          case 'dark':
            mode = {mode : 'dark' , text : 'white'}
            break;
        
          default:
            mode = {mode : 'light' , text : 'black'}
            break;
        }
        state.themeMode = mode;
      },
      setAuthentication(state, status){
        state.Authenticated = status;
      },
      setAuthenticationToken(state, status){
        appFunction.setCookie('SagaDashBoardToken', status, 365)
        state.AuthenticationToken = status;
      },
      setPainel_mes(state, status){
        state.painel_mes = status;
      },
      resetData(state){
        state.Data = {}
      },
      addData(state, status){
        var l = Object.keys(status)
        for (let i = 0; i < l.length; i++) {
          const e = status[l[i]];
          state.Data[l[i]] = e
        }
      },
      deleteData(state, status){
        delete state.Data[status]
      }
    }
  }
)


const router = new VueRouter({
  routes: [
    {path:'/', redirect:'/Menu'},
    {
      path: '/login',
      component: Login 
    },
    {
      path: '/login/:user',
      component: Login 
    },
    {
      path: '/',
      component: Painel,
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: 'Menu',
          component: Services,

        },
        {
              path: '/service/:service/',
              component: ServiceMenu,
              props: true 
        },
        {
              path: '/service_form/:service/:option/',
              component: ServiceForm,
              props: true 
        },
        {
              path: '/service_component/:service/:option/',
              component: ServiceComponent,
              props: true 
        }
      ],
      beforeEnter(to, from, next){
        if(store.state.Authenticated == false){
          next('/login');
        }else{
          next();
        }
      }
    }
  ]
})


// appFunction FUNcs 
store.commit('setThemeMode' , appFunction.detectModeTheme())
try {
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    window.console.log(e)
    store.commit('setThemeMode' , appFunction.detectModeTheme())
  });2
} catch (error) {
  window.console.log(error)
  window.console.log(error)
}




Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store: store,
  data() {
    return {
      windowHeight: window.innerHeight
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {  
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}).$mount('#app')