<template>
    <b-modal 
      id="LoadingModal" 
      title="Info" 
      hide-footer 
      hide-header 
      centered 
      no-close-on-esc 
      no-close-on-backdrop 
      :body-bg-variant="bodyBgVariant" 
      :body-text-variant="bodyTextVariant" 
      >
      <p class="my-4 text-center">{{text}}</p>
    </b-modal>
</template>

<script>
export default {
  name:'LoadingModal',
  data(){
    return {
      
    }
  },
  props: {
    bodyBgVariant: String,
    bodyTextVariant: String,
    altText : String
  },
  computed: {
    text: function (){
      if (this.altText) {
        if (this.altText.length > 0) {
          return this.altText
        }
      }
      return 'Carregando . . .'
      
    },
  }

}
</script>>