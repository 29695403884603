<template>
  <div id="services">
    <div class="text-right text-white text-decoration-none mt-2"><a href="#/Menu" :class="($store.state.themeMode.mode == 'dark')? 'text-white': 'text-dark'"><span class="sprint">VOLTAR </span>▶</a></div>
    <h3 :class="'sprint ' + (($store.state.themeMode.mode == 'dark')? 'text-white': 'text-dark')">{{$store.state.Data.pos_struct.menu_principal[service].text}}</h3> 
    <h6 :class="($store.state.themeMode.mode == 'dark')? 'text-white': 'text-dark'">{{$store.state.Data.pos_struct.menu_principal[service].name}}</h6> 
    <b-row class="justify-content-center" :key="'painelServiceRow_'+flag_changes">
      <b-col class="my-2 " cols="12" sm="12" md="10" lg="7" v-for="(item , index) in $store.state.Data.pos_struct.menu_principal[service].sub_menu" :key="index">
        <!-- <b-button class="py-3 buttonStyle" block @click="call_form(index)"><b>{{item.text}}</b></b-button> -->
        <div class="neumorphism mini text-center py-3 buttonStyle" block @click="call_form(index)"><b>{{item.text}}</b></div>
      </b-col>
    </b-row>
    <div>
    <b-modal 
    id="serviceMenuModalIndisponivel"
    hide-footer
    :header-bg-variant="($store.state.themeMode.mode == 'dark')? 'dark': 'light'"
    :header-text-variant="($store.state.themeMode.mode == 'dark')? 'light': 'dark'"
    :body-bg-variant="($store.state.themeMode.mode == 'dark')? 'dark': 'light'"
    :body-text-variant="($store.state.themeMode.mode == 'dark')? 'light': 'dark'"
    >
      <p class="my-4 text-center">Opção Indisponível!</p>
    </b-modal>
  </div>
  </div>
</template>

<script>
// import qrcode from "./QRcode";
export default {
  name: 'ServiceMenu',
  // components:{qrcode},
  data: function(){
    return{
      flag_changes: 0
    }
  },
  props: {
    service: String,
  },
  methods: {
    call_form: function(index){
      if (this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[index].form) {
        this.$router.push({ path: '/service_form/'+this.service+'/'+index })
      } else if (this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[index].component) {
        this.$router.push({ path: '/service_component/'+this.service+'/'+index })
        // this.$bvModal.show('serviceMenuModalIndisponivel')
      }
    }
  },
  beforeCreate(){
    if (typeof this.$store.state.Data.pos_struct == 'undefined') {
      this.$router.push({ path: '/Menu' })
      return false
    }
  },
  created(){
    if (typeof this.service  == 'undefined') {
      this.$router.push({ path: '/Menu' })
      return false
    }
    if (this.service.length < 1) {
      this.$router.push({ path: '/Menu' })
      return false;
    }
  }
}
</script>

<style>
.buttonStyle{
  border-radius: .2rem !important;
}
</style>