<template>
  <div :class="'py-2 ServiceForm ' + ((this.$store.state.themeMode.mode == 'dark')? 'text-white dark': 'text-dark')">
    <div v-if='fase == 1'>
      <the-mask class="my-5 w-100 text-center d-block" mask="XXXXXXXXX" type="text" :masked="true" placeholder="PLACA" v-model="placa"></the-mask>
      <the-mask class="my-5 w-100 text-center d-block" mask="######" type="tel" :masked="true" placeholder="ODOMETRO" v-model="odometro"></the-mask>
      <the-mask class="my-5 w-100 text-center d-block" mask="##########" type="tel" :masked="true" placeholder="CONDUTOR" v-model="condutor"></the-mask>
      <the-mask class="my-5 w-100 text-center d-block" mask="##########" type="password" :masked="true" placeholder="SENHA" v-model="senha"></the-mask>
      <b-button class="my-4" block @click="iniciaOrcamento" v-if=" placa.length>4 && condutor.length>0 && senha.length>3">
        CONTINUAR
      </b-button>
    </div>
    <div class="m-3 mt-4" v-if="fase == 2">
      <h3>Confirmação de dados</h3>
      <small><b>Verifique atentamente</b> os dados abaixo, ao continuar estará confirmando o condutor descrito como responsável pela compra e o veículo descrito o destino das peças e serviços adquiridos.</small>
      <b-row class="pb-4" v-for="(text, index) in ObjValida" :key="'fase2_' + index">
        <b-col cols="12" class="text-left">
          {{index}}:
        </b-col>
        <b-col cols="12"  class="text-right border-top">
          {{text}}
        </b-col>
      </b-row>
      <b-button class="my-4" block @click="descreveOrcamento" v-if=" placa.length>4 && condutor.length>0 && senha.length>3">
        CONTINUAR
      </b-button>
    </div>
    <div class="mt-4" v-if="fase == 3">
      <div>
        <div class="itemCarrinho" v-for="(item,index) in ObjItens" :key="'item_carrinho' + index"> 
          <div v-if="item.tipo == 'peca'">
            <b-row class="m-0 border-bottom">
              <b-col cols="6"><b>PEÇA</b></b-col>
              <b-col cols="6">{{item.marca}} </b-col>
            </b-row>
            <b-row class="m-0 border-bottom">
              <b-col cols="6">{{item.quantidade}} Un.</b-col>
              <b-col cols="6">{{item.valorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}} Un.</b-col>
              <b-col cols="12" class=""> {{item.descricao}}<br> <div class="text-right"><small></small></div></b-col>
            </b-row>
            <b-row class="m-0 border-bottom">
              <b-col cols="6"><b>VALOR</b></b-col>
              <b-col cols="6"><b>{{(item.valorUnitario*item.quantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</b></b-col>
            </b-row>
            <b-row class="m-0">
              <b-col cols="6" class="m-0 p-0"><b-button squared block class="m-0 p-0" variant="danger" @click="modalCompraGtmRmItem(index)"><b-icon-trash></b-icon-trash> Excluir</b-button></b-col>
              <b-col cols="6" class="m-0 p-0"><b-button squared block class="m-0 p-0" variant="secondary" @click="modalCompraGtmEditItem(index)" ><b-icon-pencil></b-icon-pencil> Alterar</b-button></b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row class="m-0 border-bottom">
              <b-col cols="12"><b>SERVIÇO</b></b-col>
            </b-row>
            <b-row class="m-0 border-bottom">
              <b-col cols="6">{{item.quantidade}} Un.</b-col>
              <b-col cols="6">{{item.valorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}} Un.</b-col>
              <b-col cols="12" class=""> {{item.descricao}}<br> <div class="text-right"><small></small></div></b-col>
            </b-row>
            <b-row class="m-0">
              <b-col cols="6"><b>VALOR</b></b-col>
              <b-col cols="6"><b>{{(item.valorUnitario*item.quantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</b></b-col>
            </b-row>
            <b-row class="m-0">
              <b-col cols="6" class="m-0 p-0"><b-button squared block class="m-0 p-0" variant="danger" @click="modalCompraGtmRmItem(index)"><b-icon-trash></b-icon-trash> Excluir</b-button></b-col>
              <b-col cols="6" class="m-0 p-0"><b-button squared block class="m-0 p-0" variant="secondary" @click="modalCompraGtmEditItem(index)" ><b-icon-pencil></b-icon-pencil> Alterar</b-button></b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="SaldoCarrinho"
      :class="'p-2 ' + ((this.$store.state.themeMode.mode == 'dark')? 'text-white bg-dark': 'text-dark bg-light')">
        <b-row class="mb-3">
          <b-col cols="6"><b-button block class="btn-sm" @click="addItem"><small>NOVO ITEM</small></b-button></b-col>
          <b-col cols="6"><b-button block class="btn-sm btn-success" @click="$bvModal.show('modalFinalizar')" :disabled="disabledFinalizar"><small>FINALIZAR</small></b-button></b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="text-left"><small>EMP/PEÇAS</small></b-col>
          <b-col cols="8" :class="'text-center ' + (ObjTotais.empenhoPecas <= ObjSaldo.empenhoPecas ? 'bg-success' : 'bg-danger')"><small>{{ObjTotais.empenhoPecas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}} / {{ObjSaldo.empenhoPecas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</small></b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="text-left"><small>EMP/SERVIÇOS</small></b-col>
          <b-col cols="8" :class="'text-center ' + (ObjTotais.empenhoServico <= ObjSaldo.empenhoServico ? 'bg-success' : 'bg-danger')"><small>{{ObjTotais.empenhoServico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}} / {{ObjSaldo.empenhoServico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</small></b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="text-left"><small>SALDO</small></b-col>
          <b-col cols="8" :class="'text-center ' + (ObjTotais.saldo <= ObjSaldo.saldo ? 'bg-success' : 'bg-danger')"><small>{{ObjTotais.saldo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}} / {{ObjSaldo.saldo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</small></b-col>
        </b-row>
      </div>
      <div style="height: 50vh"></div>
    </div>
    <div class="mt-4" v-if="fase == 4">
      <h3>Sucesso</h3>
      <h4>Orçamento {{successOrcamento}} aprovado com sucesso</h4>
      {{successMsg}}
    </div>
    <b-modal id="modalRetornoFase1" title="Info"
    :header-bg-variant="this.$store.state.themeMode.mode"
    :header-text-variant="this.$store.state.themeMode.text"
    :body-bg-variant="this.$store.state.themeMode.mode"
    :body-text-variant="this.$store.state.themeMode.text"
    hide-footer>
      <div :class="'ServiceForm ' + (($store.state.themeMode.mode == 'dark')? 'dark': '')">
        <div class=" my-4">
          {{infoFase1}}
        </div>
      </div>
    </b-modal>
    <b-modal id="modalCompraGtmAddItem" title="Novo Item"
    :header-bg-variant="this.$store.state.themeMode.mode"
    :header-text-variant="this.$store.state.themeMode.text"
    :body-bg-variant="this.$store.state.themeMode.mode"
    :body-text-variant="this.$store.state.themeMode.text"
    hide-footer>
      <div :class="'ServiceForm ' + (($store.state.themeMode.mode == 'dark')? 'dark': '')">
        <div class=" my-4">
          <b-form-select 
          v-model="addItemTipo" 
          :options="addItemTipoOptions"
          :class="'select'+(($store.state.themeMode.mode == 'dark')? '-dark': '-white') + ' selectBorderRadius'"
          ></b-form-select>
        </div>
        <div class=" my-4">
          <b-form-select 
          v-model="addCategoria" 
          :options="addCategoriaOptions"
          :class="'select'+(($store.state.themeMode.mode == 'dark')? '-dark': '-white') + ' selectBorderRadius'"
          ></b-form-select>
        </div>
        <div class="my-4" v-if="addItemTipo == 'peca'" >
          <the-mask class="w-100 text-center d-block" :mask="maskMarca" type="text" :masked="false" placeholder="MARCA" v-model="addItemMarca"></the-mask>
          <div class="text-right"><small>{{addItemMarca.length}}/20 </small></div>
        </div>
        <div v-if="addItemTipo != '' && addCategoria != '' ">
          <div class="my-4">
            <!-- <the-mask class="w-100 text-center d-block" :mask="maskDescricao" type="text" :masked="false" placeholder="DESCRIÇÃO" v-model="addItemDescricao"></the-mask> -->
            <b-form-textarea
              class="w-100 text-center d-block"
              id="textarea"
              v-model="addItemDescricao"
              placeholder="DESCRIÇÃO"
              rows="1"
              max-rows="6"
            ></b-form-textarea>
            <div class="text-right"><small>{{addItemDescricao.length}}/60 </small></div>
          </div>
          <div class="my-4">
            <the-mask class="w-100 text-center d-block" :mask="maskVidaUtil" type="tel" :masked="false" placeholder="VIDA UTIL - KM OU Hrs" v-model="addVidaUtil"></the-mask>
          </div>
          <div class="my-4">
            <the-mask class="w-100 text-center d-block" :mask="maskQuantidade" type="tel" :masked="false" placeholder="QUANTIDADE" v-model="addItemQuantidade"></the-mask>
          </div>
          <div class="my-4" >
            <money class="w-100 text-center d-block" v-model="addItemValor" v-bind="vMoney"></money>
          </div>
        </div>
        <div v-if=" addItemTipo.length > 0 && addItemMarca.length > 0 && addItemDescricao.length > 0 && addItemQuantidade > 0 && addItemValor > 0 ">
          <div class="text-center my-2">
            TOTAL: {{(addItemValor*addItemQuantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
          </div>
          <b-button block variant="success" @click="concluirAddItem">CONCLUIR</b-button>
        </div>
        <!-- <div class="mt-3"><strong>{{ addItemTipo }}</strong></div> -->
      </div>
    </b-modal>
    <b-modal id="modalCompraGtmRmItem" title="Item"
    :header-bg-variant="this.$store.state.themeMode.mode"
    :header-text-variant="this.$store.state.themeMode.text"
    :body-bg-variant="this.$store.state.themeMode.mode"
    :body-text-variant="this.$store.state.themeMode.text"
    hide-footer>
      Deseja excluir {{deleteDescricao}} ?
      <b-row class="mt-4">
        <b-col cols="6"><b-button block @click="$bvModal.hide('modalCompraGtmRmItem')">CANCELAR</b-button></b-col>
        <b-col cols="6"><b-button block variant="danger" @click="deleteItem">EXCLUIR</b-button></b-col>
      </b-row>
    </b-modal>
    <b-modal id="modalCompraGtmEditItem" title="Editar Item"
    :header-bg-variant="this.$store.state.themeMode.mode"
    :header-text-variant="this.$store.state.themeMode.text"
    :body-bg-variant="this.$store.state.themeMode.mode"
    :body-text-variant="this.$store.state.themeMode.text"
    hide-footer>
      <div :class="'ServiceForm ' + (($store.state.themeMode.mode == 'dark')? 'dark': '')">
        <div class=" my-4">
          <b-form-select 
          v-model="editItemTipo" 
          :options="editItemTipoOptions"
          :class="'select'+(($store.state.themeMode.mode == 'dark')? '-dark': '-white') + ' selectBorderRadius'"
          ></b-form-select>
        </div>
        <div class=" my-4">
          <b-form-select 
          v-model="editCategoria" 
          :options="addCategoriaOptions"
          :class="'select'+(($store.state.themeMode.mode == 'dark')? '-dark': '-white') + ' selectBorderRadius'"
          ></b-form-select>
        </div>
        <div class="my-4" v-if="editItemTipo == 'peca'">
          <the-mask class="w-100 text-center d-block" :mask="maskMarca" type="text" :masked="false" placeholder="MARCA" v-model="editItemMarca"></the-mask>
          <div class="text-right"><small>{{editItemMarca.length}}/20 </small></div>
        </div>
        <div v-if="editItemTipo != ''">
          <div class="my-4" >
            <!-- <the-mask class="w-100 text-center d-block" :mask="maskDescricao" type="text" :masked="false" placeholder="DESCRIÇÃO" v-model="editItemDescricao"></the-mask> -->
            <b-form-textarea
              class="w-100 text-center d-block"
              id="textarea"
              v-model="editItemDescricao"
              placeholder="DESCRIÇÃO"
              rows="1"
              max-rows="6"
            ></b-form-textarea>
            <div class="text-right"><small>{{editItemDescricao.length}}/60 </small></div>
          </div>
          <div class="my-4">
            <the-mask class="w-100 text-center d-block" :mask="maskVidaUtil" type="tel" :masked="false" placeholder="VIDA UTIL - KM OU Hrs" v-model="editVidaUtil"></the-mask>
          </div>
          <div class="my-4">
            <the-mask class="w-100 text-center d-block" :mask="maskQuantidade" type="tel" :masked="false" placeholder="QUANTIDADE" v-model="editItemQuantidade"></the-mask>
          </div>
          <div class="my-4">
            <money class="w-100 text-center d-block" v-model="editItemValor" v-bind="vMoney"></money>
          </div>
        </div>
        <div v-if=" editItemTipo.length > 0 && editItemMarca.length > 0 && editItemDescricao.length > 0 && editItemQuantidade > 0 && editItemValor > 0 ">
          <div class="text-center my-2">
            TOTAL: {{(editItemValor*editItemQuantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
          </div>
          <b-button block variant="success" @click="alterarItem">SALVAR</b-button>
        </div>
        <!-- <div class="mt-3"><strong>{{ editItemTipo }}</strong></div> -->
      </div>
    </b-modal>
    <b-modal id="modalFinalizar" title="Digite a senha para Finalizar"
    :header-bg-variant="this.$store.state.themeMode.mode"
    :header-text-variant="this.$store.state.themeMode.text"
    :body-bg-variant="this.$store.state.themeMode.mode"
    :body-text-variant="this.$store.state.themeMode.text"
    hide-footer>
      <b-row class="mt-4">
        <b-col cols="12" class="ServiceForm">
          <the-mask class="my-5 w-100 text-center d-block" mask="##########" type="password" :masked="true" placeholder="SENHA" v-model="finalizarSenha"></the-mask>
        </b-col>
        <b-col cols="6"><b-button block variant="danger" @click="$bvModal.hide('modalFinalizar')">CANCELAR</b-button></b-col>
        <b-col cols="6"><b-button block variant="success" :disabled="!(senha == finalizarSenha)" @click="finalizar">FINALIZAR</b-button></b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
// import {TheMask} from 'vue-the-mask'
// import money from 'v-money'
import axios from 'axios'
export default {
  name: 'CompraGtm',
  data: function(){
    return{
      placa: '',
      odometro: '',
      condutor: '',
      senha: '',
      finalizarSenha: '',
      fase: 1,
      ObjValida: {},
      ObjSaldo: {
        saldo : 0,
        empenhoPecas : 0,
        empenhoServico : 0
      },
      ObjTotais: {
        saldo : 0,
        empenhoPecas : 0,
        empenhoServico : 0
      },
      ObjItens:[],
      maskMarca: 'XXXXXXXXXXXXXXXXXXXX',
      maskDescricao: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
      maskQuantidade: '###',
      maskVidaUtil: '#######',
      maskValor: 'R$ ###',
      addItemTipoOptions: [{ value: '', text: 'SELECIONE O TIPO' },{ value: 'peca', text: 'PEÇA' },{ value: 'servico', text: 'SERVIÇO' }],
      addItemTipo: '',
      addCategoriaOptions: [{ value: '', text: 'SELECIONE UMA CATEGORIA' }],
      addCategoria: '',
      addVidaUtil: '',
      addItemMarca: '',
      addItemDescricao: '',
      addItemQuantidade: '',
      addItemValor: '',
      editItemTipoOptions: [{ value: '', text: 'SELECIONE O TIPO' },{ value: 'peca', text: 'PEÇA' },{ value: 'servico', text: 'SERVIÇO' }],
      editItemTipo: '',
      editCategoria: '',
      editVidaUtil: '',
      editItemMarca: '',
      editItemDescricao: '',
      editItemQuantidade: '',
      editItemValor: '',
      editItemFlag: null,
      vMoney: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: ' Un.',
        precision: 2,
        masked: false
      },
      disabledFinalizar: true,
      deleteItemFlag: null,
      deleteDescricao: '',
      infoFase1: '',
      successOrcamento: '',
      successMsg: '',
      categoria: '',
      vida_util: ''
    }
  },
  props: {
    URL_action: String
  },
  components: {
    // TheMask
  },
  watch:{
    addItemTipo:function (val) {
      if (val == 'servico') {
        this.addItemMarca = '-'
      }
    },
    ObjItens:{
      handler () {
        var sum = 0
        var sum_peca = 0
        var sum_servico = 0
        Object.entries(this.ObjItens).forEach(([k, el]) => {
          console.log(k, el)
          if (el.tipo == 'peca') {
            sum_peca += (el.valorUnitario*el.quantidade)
          } else {
            sum_servico += (el.valorUnitario*el.quantidade)
          }
          sum += (el.valorUnitario*el.quantidade)
        });
        this.ObjTotais = {
          saldo : sum,
          empenhoPecas : sum_peca,
          empenhoServico : sum_servico
        }
        if (
          this.ObjTotais.saldo > this.ObjSaldo.saldo || this.ObjTotais.saldo <= 0 ||
          this.ObjTotais.empenhoPecas > this.ObjSaldo.empenhoPecas ||
          this.ObjTotais.empenhoServico > this.ObjSaldo.empenhoServico 
          ) {
          this.disabledFinalizar = true
        }else{
          this.disabledFinalizar = false
        }
      },
      deep: true
    }
  },
  methods: {
    iniciaOrcamento: function(){
      this.$bvModal.show('LoadingModal')
      console.log('iniciaOrcamento')
      console.log(this.$store.state.Data.id_fornecedor_fk)
      var formdata = new FormData();

      formdata.append('placa', this.placa) 
      formdata.append('odometro', this.odometro) 
      formdata.append('condutor', this.condutor) 
      formdata.append('senha', this.senha) 
      formdata.append('fornecedor', this.$store.state.Data.id_fornecedor_fk) 
      formdata.append('token', this.$store.state.AuthenticationToken) 
      
      axios.post(this.$store.state.server_path+'GTM-B2B.php', formdata)
      .then(response => {
        this.$bvModal.hide('LoadingModal')
        window.console.log(response)
        var retorno = response.data
        if (typeof retorno.status === 'undefined') {
          this.infoFase1 == retorno
          this.$bvModal.show('modalRetornoFase1')
          return false 
        }else if (retorno.status === false) {
          console.log('this.infoFase1', retorno.msg)
          this.infoFase1 = retorno.msg
          this.$bvModal.show('modalRetornoFase1')
          return false 
        }else{
          var obj = retorno.obj
          this.ObjSaldo = {
            saldo : parseFloat(obj.saldo),
            empenhoPecas : parseFloat(obj.saldo_peca),
            empenhoServico : parseFloat(obj.saldo_servico)
          }
          this.ObjValida = {
            'Condutor' : obj.condutor,
            'Veiculo' : obj.veiculo,
            'Saldo' : parseFloat(obj.saldo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            'Saldo Empenho de peças' : parseFloat(obj.saldo_peca).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            'Saldo Empenho de Serviço' : parseFloat(obj.saldo_servico).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            'Observações' : obj.observacoes
          }
          Object.entries(obj.categorias).forEach(([k, v]) => {
            this.addCategoriaOptions.push({
              value: k,
              text: v
            })
          })
          this.fase = 2
        }
        
      })
      .catch( error => {
          this.infoFase1 == error
          this.$bvModal.show('modalRetornoFase1')
        return false 
      });

      // this.fase = 2
      // this.ObjValida = {
      //   'Condutor' : 'Marcos Alexandre da Silva Araujo',
      //   'Veiculo' : 'Cerato 2010 NPN-0804',
      //   'Saldo' : 'R$ 1200,00',
      //   'Saldo Empenho de peças' : 'R$ 1000,00',
      //   'Saldo Empenho de Serviço' : 'R$ 1200,00',
      //   'Observações' : 'Atenção -  Saldo de Emepenho peças é menor que o o saldo do veiculo'
      // }
      // this.ObjSaldo = {
      //   saldo : 1200,
      //   empenhoPecas : 1000,
      //   empenhoServico : 1200
      // }
    },
    descreveOrcamento: function () {
      this.fase = 3
      // this.ObjItens.push({"descricao":"amortecedor","marca":"kYB","tipo":"peca","quantidade":"2","valorUnitario":800})
      // this.ObjItens.push({"descricao":"Troca do amortecedor","marca":"-","tipo":"servico","quantidade":"2","valorUnitario":140})
      
    },
    addItem: function () {
      this.$bvModal.show('modalCompraGtmAddItem')
      this.addItemDescricao = ''
      this.addItemMarca = ''
      this.addItemTipo = ''
      this.addCategoria = ''
      this.addVidaUtil= ''
      this.addItemQuantidade = ''
      this.addItemValor = ''
    },
    concluirAddItem: function () {
      this.ObjItens.push({
        descricao: this.addItemDescricao,
        marca: this.addItemMarca,
        tipo: this.addItemTipo,
        quantidade: this.addItemQuantidade,
        valorUnitario: this.addItemValor,
        categoria: this.addCategoria,
        vidaUtil: this.addVidaUtil
      })
      this.$bvModal.hide('modalCompraGtmAddItem')
    },
    deleteItem: function () {
      this.ObjItens.splice(this.deleteItemFlag, 1)
      this.$bvModal.hide('modalCompraGtmRmItem')
      this.deleteItemFlag = null
      this.deleteDescricao = ''
    },
    modalCompraGtmRmItem: function (index) {
      this.deleteItemFlag = index
      this.deleteDescricao = this.ObjItens[index].descricao + ' ' + this.ObjItens[index].quantidade + ' Un.'
      this.$bvModal.show('modalCompraGtmRmItem')
    },
    modalCompraGtmEditItem: function (index) {
      this.editItemFlag = index
      this.editItemTipo = this.ObjItens[index].tipo
      this.editItemMarca = this.ObjItens[index].marca
      this.editItemDescricao = this.ObjItens[index].descricao
      this.editItemQuantidade = this.ObjItens[index].quantidade
      this.editItemValor = this.ObjItens[index].valorUnitario
      this.$bvModal.show('modalCompraGtmEditItem')
    },
    alterarItem: function () {      
      this.ObjItens[this.editItemFlag].descricao = this.editItemDescricao,
      this.ObjItens[this.editItemFlag].marca = this.editItemMarca,
      this.ObjItens[this.editItemFlag].tipo = this.editItemTipo,
      this.ObjItens[this.editItemFlag].categoria = this.editCategoria,
      this.ObjItens[this.editItemFlag].vidaUtil = this.editVidaUtil,
      this.ObjItens[this.editItemFlag].quantidade = this.editItemQuantidade,
      this.ObjItens[this.editItemFlag].valorUnitario = this.editItemValor
      this.$bvModal.hide('modalCompraGtmEditItem')
      this.editItemDescricao = ''
      this.editCategoria = ''
      this.editVidaUtil= ''
      this.editItemMarca = ''
      this.editItemTipo = ''
      this.editItemQuantidade = ''
      this.editItemValor = ''
    },
    finalizar: function () {
      console.log(this.URL_action)
      this.$bvModal.show('LoadingModal')
      var fornecedor = this.$store.state.Data.id_fornecedor_fk 
      var usuario = this.$store.state.Data.id_usuario

      var formdata = new FormData();
      
      var data = JSON.stringify(
        {
          usuario: usuario,
          id_fornecedor: fornecedor,
          placa : this.placa,
          odometro : this.odometro,
          condutor : this.condutor,
          senha : this.senha,
          itens : this.ObjItens
        } 
      )
      formdata.append('data', data) 
      console.log(data)
      axios.post(this.URL_action, formdata)
      .then(response => {
        this.$bvModal.hide('LoadingModal')
        window.console.log(response)
        var retorno = response.data
        if (typeof retorno.status === 'undefined') {
          console.log('status == undefined')
          this.infoFase1 == retorno
          this.$bvModal.show('modalRetornoFase1')
          return false 
        }else if (retorno.status === false) {
          console.log('status == false')
          console.log('this.infoFase1', retorno.msg)
          this.infoFase1 = retorno.msg
          this.$bvModal.show('modalRetornoFase1')
          return false 
        }else{
          this.$bvModal.hide('LoadingModal')
          this.successOrcamento = retorno.orcamento
          this.successMsg = retorno.msg !== null ?  retorno.msg : 'Para finalizar é nescessario anexar as notas fiscais no sistema GTM - INFORMAR FINANCEIRO'
          this.fase = 4
        }
      })
      .catch( error => {
          this.$bvModal.hide('LoadingModal')
          this.infoFase1 == error
          this.$bvModal.show('modalRetornoFase1')
        return false 
      });
    }
  }
}
</script>
<style scoped>
 .ServiceForm input{
    background: none;
    color: black;
    border: none;
    border-bottom: 1px solid darkslategrey;
  }
  .ServiceForm input:focus{
    background: none;
     outline: none;
    border: none;
    border-bottom: 1px solid black;
  }

  .ServiceForm.dark input{
    background: none;
    color: white;
    border: none;
    border-bottom: 1px solid lightgray;
  }
  .ServiceForm.dark input:focus{
    background: none;
     outline: none;
    border: none;
    border-bottom: 1px solid white;
  }
  .SaldoCarrinho{
    border: 1px solid gray;
    /* width: ; */
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .itemCarrinho{
    border: 1px solid gray;
    margin-top: 20px;
    border-radius: 1rem;
     overflow:hidden;
  }
  /* .bordaActionbuttons{
    border-radius: 1rem;
  } */
  .selectBorderRadius{
    border-radius: 1rem !important;
  }
</style>