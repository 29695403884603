<template>
  <div id="login" class="gradiente-background">
    <div class="container" id="login-container">
      <b-card id="login-card" class="m-auto " :bg-variant="this.$store.state.themeMode.mode" :text-variant="this.$store.state.themeMode.text" >
        <div style="font-size:2rem; font-weight:900;"><i style="color:#1a3f48">POS VIRTUAL</i></div>
        <img v-bind:src="(this.$store.state.themeMode.mode == 'dark')? require(`@/assets/GTF5.png`): require(`@/assets/GTF4.png`)" class="mw-100 ">
        <div id="login-row" class="row justify-content-center align-items-center" >
          <div id="login-column" class="col-md-12">
            <div id="login-box" class="col-md-12">
              <div class="form-group">
                <input type="email" class="form-control text-center mb-4" v-model="usuario" placeholder="Usuario" />
              </div>
              <div class="form-group">
                <input type="password" name="text" id="password" class="form-control text-center mb-4" placeholder="Senha"  v-model="senha" @keyup.enter="login">
              </div>
              <div class="form-group">
                <input type="submit" name="submit" class="btn btn-block p-3 btn-success btn-md" value="Entrar" v-on:click="login">
                <!-- <login-button @login="login()" /> -->
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <b-modal id="modal-1" title="Info" hide-footer hide-header centered body-bg-variant="dark" body-text-variant="light" >
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="hideModal" >X</button>
      </div>
      <p class="mb-4  text-center"> {{modalMsg}} </p>
    </b-modal>
    <LoadingModal 
      :body-bg-variant="this.$store.state.themeMode.mode" 
      :body-text-variant="this.$store.state.themeMode.text" />
  </div>
</template>

<script>
  import axios from 'axios'
  import LoadingModal from "./loading-modal.vue"
  import appCookie from '../js/functions'
// import LoginButton from './LoginButton.vue'
  
  export default {
    name: 'Login',
    props: {
      msg: String,
      user: String
    },
    data(){
      return{
        usuario : '',
        senha : '',
        modalMsg : ''
      }
    },
    components: {
      LoadingModal,
      //  LoginButton
       },
    methods : {
      hideModal () {
        this.$bvModal.hide('modal-1')
      },
      login() {
        var formdata = new FormData();
        if (this.$store.state.AuthenticationToken == '') {
          if (this.usuario === '') { 
            this.modalMsg = 'Informe o usuario'
            this.$bvModal.show('modal-1')
            return false
          }
          if (this.senha === '') { 
            this.modalMsg = 'Informe uma Senha'
            this.$bvModal.show('modal-1')
            return false
          }
          if (this.senha < 6) { 
            this.modalMsg = 'Senha Inválida'
            this.$bvModal.show('modal-1')
            return false
          }
          this.$bvModal.show('LoadingModal')
          var integridade = btoa(this.usuario+btoa(this.senha))
          console.log(integridade)
          formdata.append('usuario', this.usuario)
          formdata.append('senha', btoa(this.senha))
          formdata.append('integridade', integridade)
          axios.post(this.$store.state.server_path+'login.php', formdata)
          .then(response => {
            window.console.log(response)
            this.$bvModal.hide('LoadingModal')
            if (response.data.status) {
              this.$store.commit("setAuthentication", true)
              this.$store.commit("setAuthenticationToken", response.data.obj.token)
              this.$store.commit("addData", response.data.obj.userData)
              this.$router.push({ path: '/Menu' })
            }else{
              this.info(response.data.msg)
            }
          })
          .catch( error => {
            this.$bvModal.hide('LoadingModal')
            this.info = error; 
            this.modalMsg =  this.info
            this.$bvModal.show('modal-1')
            return false 
          });
        }else{
          this.$bvModal.show('LoadingModal')
          formdata.append('token', this.$store.state.AuthenticationToken);
          axios.post(this.$store.state.server_path+'/login.php', formdata)
          .then(response => {
            window.console.log(response)
            this.$bvModal.hide('LoadingModal')
            if (response.data.status) {
              this.$store.commit("setAuthentication", true)
              this.$store.commit("addData", response.data.obj.userData)
              this.$router.push({ path: '/Menu' })
            }else{
              this.info(response.data.msg)
              this.$store.commit("setAuthenticationToken", "")
            }
          })
          .catch( error => {
            this.$bvModal.hide('LoadingModal')
            this.info = error; 
            this.modalMsg =  this.info
            this.$bvModal.show('modal-1')
            this.$store.commit("setAuthenticationToken", "")
            return false 
          });
        }
      },
      loginGTF() {
        var formdata = new FormData();
        formdata.append('session_id', this.$route.params.user)
        this.$bvModal.show('LoadingModal')
        const headers = {
        }
        axios.post('https://www.sistemagtf.com.br/integracao_fornecedor/server/pos_rescue_login.php', formdata, { headers: headers, crossdomain: true })
        .then(response => {
          window.console.log(response)
          this.$bvModal.hide('LoadingModal')
          if (response.data.status) {
            this.$store.commit("setAuthentication", true)
            this.$store.commit("setAuthenticationToken", response.data.obj.token)
            this.$store.commit("addData", response.data.obj.userData)
            this.$router.push({ path: '/Menu' })
          }else{
            this.info(response.data.msg)
          }
        })
        .catch( error => {
          this.$bvModal.hide('LoadingModal')
          this.info = error; 
          this.modalMsg =  this.info
          this.$bvModal.show('modal-1')
          return false 
        });
      },
      info(msg){
        this.modalMsg = msg
        this.$bvModal.show('modal-1')
      }
    },
    created(){
      window.console.log(this)
    },
    mounted(){
      console.log(this.$route.params.user)
      if (typeof this.$route.params.user !== 'undefined') {
        this.usuario = this.$route.params.user
        this.loginGTF()
      }
      var token = appCookie.getCookie('SagaDashBoardToken')
      window.console.log('montado: '+token)
      if (token != false) {
        this.$store.commit("setAuthenticationToken", token)
        this.login()
      } 
      // else {
      //   if ( Object.prototype.hasOwnProperty.call(this.$route.params, 'user') ) {
      //     this.loginGTF()
      //   }
      // }
    }
  }
</script>


<style scoped>
    #login-card{
      max-width: 400px;
      /* background-color: #FFFF; */
    }
    #login-container{
      padding-top: 10vh;
      /* padding-bottom: 15vh; */
    }
    /* .gradiente-background{
      background-image: linear-gradient(to right, #C4EDE1 , #8CA9FF);
    } */
    #login{
      height: 100vh;
    }
</style>