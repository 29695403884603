export function printTeste() {
  const text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet suscipit nisi, eu tincidunt lorem. Phasellus sodales diam eget cursus hendrerit. Curabitur lobortis nunc eu sem lacinia, nec lacinia sapien malesuada. Phasellus ultrices neque nisl, a vehicula ex interdum vitae. Duis non luctus neque, eu laoreet purus. Fusce venenatis condimentum vehicula. Quisque fermentum sodales lorem, ut sagittis enim suscipit et. In hac habitasse platea dictumst. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed scelerisque nisl. Mauris et libero scelerisque, suscipit est vitae, efficitur risus. Sed lacinia nibh mauris, vitae gravida nibh efficitur at. Sed consectetur pulvinar justo, ut vulputate libero egestas non. Aenean lectus lectus, vestibulum sed pellentesque vitae, pulvinar nec orci.

  Donec viverra et libero et ornare. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce vel venenatis purus, vitae convallis orci. Aliquam maximus convallis tellus, id lacinia velit consectetur at. Morbi in dui cursus, aliquet lacus a, facilisis orci. Vestibulum laoreet lobortis ante, sed venenatis tortor suscipit sit amet. Donec sed risus non nisl malesuada maximus auctor at augue. Fusce condimentum ullamcorper massa at hendrerit. Duis viverra sapien nec viverra rutrum. Vivamus ex nisi, facilisis nec sem in, dictum mattis lectus. Etiam mollis fermentum leo nec fringilla. Sed condimentum semper libero at dapibus. Suspendisse eu odio justo. Suspendisse porttitor ligula lectus, ac feugiat velit mattis vitae.
  
  Sed molestie imperdiet congue. Nam vel tellus ut arcu eleifend eleifend. Suspendisse faucibus vel erat et egestas. Duis eu lacinia felis. Curabitur turpis eros, lobortis eu metus quis, laoreet accumsan nunc. Nullam consequat pulvinar urna, interdum consequat libero ultricies vel. Duis cursus velit sodales neque ultrices volutpat. Proin eu dolor egestas, fringilla sapien non, luctus ex. Nullam quis nunc et ex laoreet accumsan quis et metus. In non tortor sollicitudin, aliquet leo pharetra, pellentesque massa. In purus diam, ornare quis elementum id, sagittis nec velit. Donec ac euismod nibh. Morbi tellus arcu, mattis vitae leo quis, auctor congue ex. Curabitur ullamcorper commodo magna, vel porta nisl porttitor ut. Nam suscipit lacus ac erat varius iaculis.
  
  Praesent id augue augue. Suspendisse consectetur, diam eget maximus semper, nunc velit condimentum felis, vestibulum iaculis lorem lacus vitae diam. Integer sodales ex quis malesuada volutpat. Proin sit amet tincidunt sapien, a molestie sapien. Vestibulum vitae neque dignissim, ultrices libero non, sollicitudin arcu. Nulla aliquet cursus arcu, eget gravida sapien faucibus nec. Aenean in nisl nibh. Morbi venenatis ultrices dolor at commodo. Sed mauris est, viverra vel facilisis non, venenatis id erat. Suspendisse faucibus ultricies gravida. Vivamus sodales efficitur eros, nec dignissim magna. Ut id facilisis arcu. Etiam aliquet ligula et sem efficitur pharetra.
  
  Morbi tempor commodo urna. Mauris hendrerit ultrices rutrum. Phasellus sem metus, blandit eget erat sed, auctor scelerisque libero. Nam laoreet libero in ligula tempor, a vestibulum libero hendrerit. Donec eu venenatis sem. Donec hendrerit urna nec sem imperdiet cursus. Aenean vel molestie lorem. Nunc faucibus purus non dui feugiat, eu vulputate dolor tincidunt.`
  try {
    window.Android.print('\n\n' + text + '\n\n\n\n')
  } catch (error) {
    try {
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      )

      WinPrint.document.write(`
        <!DOCTYPE html>
        <html>
          <body>
            ${text}
          </body>
        </html>
      `)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    } catch (error) {
      alert('impressora termina não disponivel: ' + error.message)
    }
  }
}
