<template>
  <div id="services">
    <b-row>
      <b-col cols="6">
        <div :class="'mt-2 ellipsis text-left ' + ((this.$store.state.themeMode.mode == 'dark')? 'text-white': 'text-dark')">
          <span class="sprint"> {{this.$store.state.Data.pos_struct.menu_principal[this.service].text}} </span>/ {{this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[this.option].text}}
        </div>
      </b-col>
      <b-col cols="6">
        <div class="text-right text-white text-decoration-none mt-2"><a :href="'#/service/' + service + '/'" :class="(this.$store.state.themeMode.mode == 'dark')? 'text-white': 'text-dark'"><span class="sprint">VOLTAR </span>▶</a></div>
      </b-col>
    </b-row>
    <div v-if="$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[this.option].component == 'CompraGTM'">
      <CompraGtm :URL_action="$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[this.option].URL_action"/>
    </div>
    <div v-else-if="$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[this.option].component == 'listProtocolos'">
      <list-protocolos/>
    </div>
    <b-modal 
      id="modalMsg" 
      :title="modalMsg.title"  
      ok-only
      :header-bg-variant="this.$store.state.themeMode.mode"
      :header-text-variant="this.$store.state.themeMode.text"
      :body-bg-variant="this.$store.state.themeMode.mode"
      :body-text-variant="this.$store.state.themeMode.text"
      hide-footer
      >
      <p class="my-4">{{modalMsg.msg}}</p>
    </b-modal>
    <!-- <LoadingModal 
      :body-bg-variant="this.$store.state.themeMode.mode" 
      :body-text-variant="this.$store.state.themeMode.text" /> -->
    <ComprovanteModal
      :body-bg-variant="this.$store.state.themeMode.mode" 
      :body-text-variant="this.$store.state.themeMode.text" 
      :msg="comprovante.msg"
      :TitleModal="comprovante.TitleModal"
      :btnSave="comprovante.btnSave"
      :btnTryAgain="comprovante.btnTryAgain"
    />
    <QRcodeModal 
      :body-bg-variant="this.$store.state.themeMode.mode" 
      :body-text-variant="this.$store.state.themeMode.text" 
    />
  </div>
</template>

<script>
  // import LoadingModal from "./loading-modal.vue"
  import ComprovanteModal from "./comprovante-modal.vue"
  import QRcodeModal from "./qrcode-modal.vue"
  import CompraGtm from "./CompraGTM"
import ListProtocolos from './listProtocolos.vue'
  // import {TheMask} from 'vue-the-mask'

export default {
  name: 'ServiceComponent',
  components:{
    // LoadingModal,
    ComprovanteModal,
    // TheMask,
    QRcodeModal,
    CompraGtm,
    ListProtocolos
  },
  data: function(){
    return{
      comprovante : {
        msg : '',
        TitleModal : 'Retorno: ', 
        btnSave : false, 
        btnTryAgain: false
      },
      modalMsg:{
        msg : '',
        title : '', 
      },
      qrcode:'',
      qrCodeReading: false,
      qrCodeReadingFeedBack: '',
    }
  },
  props: {
    service: String,
    option: String,
  },
}
</script>

<style>
  .ServiceForm input{
    background: none;
    color: black;
    border: none;
    border-bottom: 1px solid darkslategrey;
  }
  .ServiceForm input:focus{
    background: none;
     outline: none;
    border: none;
    border-bottom: 1px solid black;
  }

  .ServiceForm.dark input{
    background: none;
    color: white;
    border: none;
    border-bottom: 1px solid lightgray;
  }
  .ServiceForm.dark input:focus{
    background: none;
     outline: none;
    border: none;
    border-bottom: 1px solid white;
  }
  .select-dark{
    background: black !important;
    color: white !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid white !important;
    text-align: center !important;
    text-align-last:center;

  }
  .select-dark option{
    background: black;
    color: white;
    border: none;
    border-radius: 0;
    text-align: center;
  }

  .select-white{
    background: #d3d3d3 !important;
    color: black !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid black !important;
    text-align: center !important;
    text-align-last:center;

  }
  .select-white option{
    background: #d3d3d3;
    color: black;
    border: none;
    border-radius: 0;
    text-align: center;
  }
  .ellipsis{
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>