<template>
    <b-modal 
      id="QRcodeModal" 
      :title="TitleModal" 
      hide-footer 
      hide-header 
      centered 
      no-close-on-esc 
      no-close-on-backdrop 
      :body-bg-variant="bodyBgVariant" 
      :body-text-variant="bodyTextVariant" 
      >
        <h5>{{TitleModal}}</h5>
        <div class="my-4 text-center">
          <div id="recibo_bg">
            <video autoplay id="video"></video> 
          </div>
        </div>
        <br>
        <b-row>
            <b-col cols="6">
                  <b-button block @click="$bvModal.hide('QRcodeModal')">Tentar Nov.</b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>

export default {
  name:'QRcodeModal',
  data(){
    return {
      TitleModal: 'Ler QRcode' 
    }
  },
  props: {
    bodyBgVariant: String,
    bodyTextVariant: String,
  },
  methods : {
    enumerateDevices: async function getDevices() {
      const constraints = {
          video: {
            width: {
              min: 1280,
              ideal: 1920,
              max: 2560,
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1440,
            },
          },
        }
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log(devices)
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const video = document.querySelector('video');
      video.srcObject = stream;
    },
    mounted: function(){
      this.enumerateDevices()
    }
  }
}
</script>
<style scoped>
#video{
  width: 100%;
}
</style>