<template>
  <div id="services" class="m-auto">
    <div class="text-center pt-4">
      <b>{{$store.state.Data.nome_fantasia}}</b>
    </div>
    <div class="text-center pt-2">
      <b>BEM VINDO, {{$store.state.Data.nome}}</b>
    </div>
    <b-row class="my-3 p-0 m-0 justify-content-center " :key="'painelServiceRow_'+flag_changes">
      <b-col class="p-2 " cols="6" sm="6" md="4" v-for="(service , index) in services_computed" :key="index" @click="call_service(index)">
        <b-card
          :img-src="require('@/assets/'+($store.state.themeMode.mode=='dark'?service.img_dark:service.img))"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem; white-space: nowrap"
          class="m-auto mb-2 text-center border-0 neumorphism mini"
        >
          <b-card-text class="text-services">
            <div :style="'color:' + service.text_color">{{service.text}}</div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import qrcode from "./QRcode";
export default {
  name: 'Services',
  // components:{qrcode},
  data: function(){
    return{
    }
  },
  props: {
    flag_changes: Number,
    services: Object
  },
  methods:{
    call_service: function(service){
      this.$router.push({ path: '/service/'+service+'/' })
    }
  },
  computed: {
    services_computed: function () {
      var services = {}
      Object.entries(this.services).forEach( ([a, b]) => {
        if(b.status == 'ativo' && b.tipo_usuario == this.$store.state.Data.tipo_usuario ) services[a] = b
      })
      return services
    }
  }
}
</script>
<style scoped>
  .img-services{width: 30%; display: inline;}
  .text-services{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: .8em;
  }
</style>