<template>
  <div id="services">
    <b-row>
      <b-col cols="6">
        <div
          :class="
            'mt-2 ellipsis text-left ' +
            (this.$store.state.themeMode.mode == 'dark'
              ? 'text-white'
              : 'text-dark')
          "
        >
          <span class="sprint">
            {{
              this.$store.state.Data.pos_struct.menu_principal[this.service]
                .text
            }} </span
          >/
          {{
            this.$store.state.Data.pos_struct.menu_principal[this.service]
              .sub_menu[this.option].text
          }}
        </div>
      </b-col>
      <b-col cols="6">
        <div class="text-right text-white text-decoration-none mt-2">
          <a
            :href="'#/service/' + service + '/'"
            :class="
              this.$store.state.themeMode.mode == 'dark'
                ? 'text-white'
                : 'text-dark'
            "
            ><span class="sprint">VOLTAR </span>▶</a
          >
        </div>
      </b-col>
    </b-row>
    <b-row
      :class="'m-auto ServiceForm ' + this.$store.state.themeMode.mode"
      :key="'painelServiceRow_' + flag_changes"
      style="max-width: 500px"
    >
      <div class="mt-4">
        Passo {{ step }} de {{ Object.keys(steppers).length + 1 }}
      </div>
      <b-col
        cols="12"
        sm="12"
        md="12"
        v-for="(item, index) in computed_form"
        :key="index"
      >
        <div
          v-show="item.type != 'hidden' && step == steppers[index]"
          :class="
            ' ' +
            ($store.state.themeMode.mode == 'dark' ? 'text-white' : 'text-dark')
          "
        >
          <!-- {{index}} -->
          <label class="mb-1 mt-1" :for="service + option + index"
            ><h5 class="m-0">{{ item.text }}</h5></label
          >
          <div v-if="item.qrcode">
            <div v-if="!qrCodeReading" class="text-right">
              <b-button class="my-4" @click="qrCodeRead(index)">
                <div style="display: inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-upc-scan"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
                    />
                  </svg>
                </div>
                <div>QR Code</div>
              </b-button>
            </div>
            <div v-if="qrCodeReading">
              <div class="my-3">
                <qrcode-stream @decode="(e) => onDecode(e, index)">
                  <div class="text-right">
                    <b-button variant="danger" @click="cancelQR"
                      >Fechar</b-button
                    >
                  </div>
                </qrcode-stream>
              </div>
              <h6>
                {{ qrCodeReadingFeedBack }}
              </h6>
            </div>
          </div>
          <div v-if="item.type === 'tel'">
            <the-mask
              class="w-100 text-center d-block"
              :mask="item.mask"
              :type="item.type"
              :disabled="isAndroid && item.disabled"
              :masked="false"
              :placeholder="item.text"
              v-model="obj_submit[index]"
            ></the-mask>
          </div>
          <div v-else-if="item.type === 'number'">
            <money
              v-if="Object.prototype.hasOwnProperty.call(item, 'precision')"
              v-model="obj_submit[index]"
              :disabled="isAndroid && item.disabled"
              v-bind="{
                ...number_mask,
                precision: item.precision,
                prefix: Object.prototype.hasOwnProperty.call(item, 'prefix')
                  ? item.prefix
                  : '',
                suffix: Object.prototype.hasOwnProperty.call(item, 'suffix')
                  ? item.suffix
                  : '',
              }"
              class="w-100 text-center d-block"
            >
            </money>
            <vue-mask
              v-else
              class="w-100 text-center d-block"
              v-model="obj_submit[index]"
              :mask="item.mask"
              :placeholder="item.text"
              :disabled="isAndroid && item.disabled"
              :raw="true"
              :options="{ reverse: true }"
            >
            </vue-mask>
          </div>
          <div v-else-if="item.type === 'select'">
            <b-form-select
              v-model="obj_submit[index]"
              :options="item.options"
              :disabled="isAndroid && item.disabled"
              :class="
                'select' +
                ($store.state.themeMode.mode == 'dark' ? '-dark' : '-white')
              "
            ></b-form-select>
          </div>
          <div v-else-if="item.type === 'radio'">
            <br />
            <b-form-radio-group
              v-model="obj_submit[index]"
              :options="item.options"
              name="radio-options"
              :disabled="isAndroid && item.disabled"
              :class="
                'radio' +
                ($store.state.themeMode.mode == 'dark' ? '-white' : '-dark')
              "
            ></b-form-radio-group>
          </div>
          <div v-else-if="item.type === 'textarea'">
            <textarea
              rows="5"
              v-model="obj_submit[index]"
              class="w-100"
              :disabled="isAndroid && item.disabled"
            >
            </textarea>
          </div>
          <div v-else-if="item.type === 'file'">
            <input
              type="file"
              :id="'ServiceForm_' + index"
              :ref="'ServiceForm_' + index"
              :disabled="isAndroid && item.disabled"
              @change="
                (event) => {
                  previewFiles(event.target, index)
                }
              "
              class="w-100"
              :accept="
                Object.prototype.hasOwnProperty.call(item, 'accept')
                  ? item.accept
                  : ''
              "
            />
            <div v-if="Object.prototype.hasOwnProperty.call(item, 'accept')">
              <div v-if="item.accept.match(/image/) != null" class="mt-4">
                <img ref="file_preview" alt="PREVIEW" style="max-width: 100%" />
              </div>
            </div>
          </div>
          <div v-else-if="item.type === 'password'">
            <the-mask
              class="w-100 text-center d-block"
              :mask="item.mask"
              type="tel"
              :masked="true"
              :placeholder="item.text"
              v-model="obj_submit[index]"
              :disabled="isAndroid && item.disabled"
              style="-webkit-text-security: disc !important"
            ></the-mask>
          </div>
          <div v-else-if="item.type === 'selectPA'">
            <b-form-select
              v-model="obj_submit[index]"
              :options="$store.state.Data.lista_pa"
              :disabled="isAndroid && item.disabled"
              :class="
                'select' +
                ($store.state.themeMode.mode == 'dark' ? '-dark' : '-white')
              "
            ></b-form-select>
          </div>
          <div v-else-if="item.type !== 'hidden'">
            <div v-if="item.mask != false">
              <the-mask
                class="w-100 text-center d-block"
                :mask="item.mask"
                :type="item.type"
                :masked="false"
                :placeholder="item.text"
                :disabled="isAndroid && item.disabled"
                v-model="obj_submit[index]"
              ></the-mask>
            </div>
            <div v-else>
              <input
                class="w-100 text-center d-block"
                :type="item.type"
                :placeholder="item.text"
                :disabled="isAndroid && item.disabled"
                v-model="obj_submit[index]"
              />
            </div>
          </div>
          <!-- <b-input-group >
            <b-input class="text-center rounded-0" :id="service+option+index"  :type="item.type" v-mask="item.mask" v-model="obj_submit[index]" ></b-input>
          </b-input-group> -->
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="step == Object.keys(this.steppers).length + 1"
      class="m-auto"
      style="max-width: 500px"
    >
      <b-col cols="12">
        <h5>Confirmação</h5>
      </b-col>
      <b-row
        class="border"
        v-for="(item, index) in computed_form"
        :key="'confirm_' + index"
        style="width: 100%"
      >
        <b-col cols="6">
          {{ item.text }}
        </b-col>
        <b-col cols="6" class="text-right">
          <span v-if="Object.prototype.hasOwnProperty.call(item, 'prefix')">{{
            item.prefix
          }}</span>
          <span
            v-if="Object.prototype.hasOwnProperty.call(item, 'precision')"
            >{{ parseFloat(obj_submit[index]).toLocaleString('pt-BR') }}</span
          >
          <span v-else-if="item.type == 'select'">
            {{ item.options.find((e) => e.value == obj_submit[index]).text }}
          </span>
          <span v-else-if="item.type == 'password'"> ****** </span>
          <span v-else-if="item.type == 'file'">
            {{ obj_submit[index].name }}
          </span>
          <span v-else>{{ obj_submit[index] }}</span>
          <span v-if="Object.prototype.hasOwnProperty.call(item, 'suffix')">{{
            item.suffix
          }}</span>
        </b-col>
      </b-row>
      <b-col cols="12">
        <b-button class="my-4" block @click="submit">
          {{ btn_text }}
        </b-button>
      </b-col>
      <b-col cols="6">
        <b-button class="my-4" block @click="backStep" :disabled="step == 1">
          Voltar
        </b-button>
      </b-col>
    </b-row>
    <b-row v-else class="m-auto" style="max-width: 500px">
      <b-col cols="6">
        <b-button class="my-4" block @click="backStep" :disabled="step == 1">
          Voltar
        </b-button>
      </b-col>
      <b-col cols="6">
        <b-button class="my-4" block @click="nextStep"> Proximo </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="modalMsg"
      :title="modalMsg.title"
      ok-only
      :header-bg-variant="this.$store.state.themeMode.mode"
      :header-text-variant="this.$store.state.themeMode.text"
      :body-bg-variant="this.$store.state.themeMode.mode"
      :body-text-variant="this.$store.state.themeMode.text"
      hide-footer
    >
      <p class="my-4">{{ modalMsg.msg }}</p>
    </b-modal>
    <ComprovanteModal
      :body-bg-variant="this.$store.state.themeMode.mode"
      :body-text-variant="this.$store.state.themeMode.text"
      :msg="comprovante.msg"
      :TitleModal="comprovante.TitleModal"
      :btnSave="comprovante.btnSave"
      :btnTryAgain="comprovante.btnTryAgain"
      :autoPrint="comprovante.autoPrint"
    />
    <QRcodeModal
      :body-bg-variant="this.$store.state.themeMode.mode"
      :body-text-variant="this.$store.state.themeMode.text"
    />
  </div>
</template>

<script>
import axios from 'axios'

import ComprovanteModal from './comprovante-modal.vue'
import QRcodeModal from './qrcode-modal.vue'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'ServiceMenu',
  components: {
    ComprovanteModal,
    TheMask,
    QRcodeModal,
  },
  data: function () {
    return {
      step: 1,
      steppers: [],
      Modal_Title: '',
      Modal_Text: '',
      flag_changes: 0,
      btn_text:
        this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[
          this.option
        ].button_text,
      obj_submit: {},
      teste: '',
      comprovante: {
        msg: '',
        TitleModal: 'Retorno: ',
        btnSave: false,
        btnTryAgain: false,
        autoPrint: false,
      },
      modalMsg: {
        msg: '',
        title: '',
      },
      qrcode: '',
      qrCodeReading: false,
      qrCodeReadingFeedBack: '',
      number_mask: {
        decimal: ',',
        thousands: '.',
        // precision: 0,
        masked: false,
      },
    }
  },
  props: {
    service: String,
    option: String,
  },
  methods: {
    cancelQR() {
      this.qrCodeReading = false
      this.qrCodeReadingFeedBack = 'Leitura Cancelada'
    },
    previewFiles(target, index) {
      const [file] = target.files
      if (file) {
        const url = URL.createObjectURL(file)
        const name = this.$refs['ServiceForm_' + index][0].files[0].name
        this.$refs.file_preview[0].src = url
        this.obj_submit[index] = {
          file: file,
          name: name,
        }
      }
    },
    backStep() {
      if (this.step > 1) {
        this.step--
      }
    },
    nextStep() {
      if (this.step < Object.keys(this.steppers).length + 1) {
        this.step++
      }
    },
    processMask: function (val, item, index) {
      var zeros = item.mask.match(/,(.+)/)
      if (zeros === null) {
        return false
      }
      var val_format = val.match(/^[0]+,(\d+)/)
      if (val_format != null) {
        val = val_format[1]
      }
      if (zeros[1].length > val.length) {
        this.obj_submit[index] = '0,' + val
        // this.flag_changes++
      }
    },
    callqrcode: function () {
      this.$bvModal.show('QRcodeModal')
    },
    submit: function () {
      this.$bvModal.show('LoadingModal')
      // EXEMPLO:
      // fonte.saganews.com.br:8080/fonte/pos.php?
      // action=abasteceVeiculo
      // &num_cartao=0010001301730601
      // &cod_condutor=11
      // &cod_combustivel=1
      // &qtd_litros=11.20
      // &odometro=0
      // &senha=001199
      // &cod_fornecedor=1
      // &serialpos=0

      // var formdata = {}
      // formdata.action =  this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[this.option].parametros.action
      // formdata.cod_fornecedor =  this.$store.state.Data.id_fornecedor_fk
      // formdata.serialpos =  'POSAPP'+this.$store.state.Data.id_usuario
      // formdata.POSAPP =  'ALPHA'
      // Object.entries(this.obj_submit).forEach((e)=>{
      //   formdata[e[0]] = e[1]
      // })

      var formdata = new FormData()
      formdata.append(
        'to',
        this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[
          this.option
        ].URL_action
      )
      formdata.append('token', this.$store.state.AuthenticationToken)

      var params = {}
      params['action'] =
        this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[
          this.option
        ].parametros.action
      params['cod_fornecedor'] = this.$store.state.Data.id_fornecedor_fk
      params['serialpos'] = 'POSAPP' + this.$store.state.Data.id_usuario
      params['posapp'] = 'ALPHA'
      var computed = this.computed_form
      Object.entries(this.obj_submit).forEach(
        ([index, item, formData = formdata]) => {
          var e = [index, item]
          if (typeof e[1] === 'number') {
            params[e[0]] = e[1]
              .toFixed(computed[e[0]].precision)
              .replaceAll('.', '')
            return false
          } else if (
            computed[e[0]].type == 'file' &&
            Object.prototype.hasOwnProperty.call(e[1], 'file')
          ) {
            formData.append(e[0], e[1].file, e[1].name)
            params[e[0]] = { type: 'file', param: e[0] }
          } else {
            params[e[0]] = e[1]
          }
        }
      )
      formdata.append('params', JSON.stringify(params))
      axios
        .post(this.$store.state.server_path + 'pos.php', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.$bvModal.hide('LoadingModal')
          try {
            var obj_response = response.data

            // esta esperando JSON com dois parametros 'status' e 'msg'
          } catch (error) {
            this.comprovante.msg = response.data
            this.comprovante.TitleModal = 'Atenção'
            this.comprovante.btnSave = false
            this.comprovante.btnTryAgain = true

            // this.ServiceFormModal('Sucesso', obj_response.msg)

            this.$bvModal.show('ComprovanteModal')

            // this.ServiceFormModal('Resposta', response.data)
            return false
          }

          if (typeof obj_response.status == 'undefined') {
            this.comprovante.msg = response.data
            this.comprovante.TitleModal = 'Transação não realizada'
            this.comprovante.btnSave = false
            this.comprovante.btnTryAgain = true

            // this.ServiceFormModal('Sucesso', obj_response.msg)

            this.$bvModal.show('ComprovanteModal')
          } else if (obj_response.status == true) {
            this.comprovante.msg = obj_response.msg
            this.comprovante.TitleModal = 'Transação realizada com Sucesso'
            this.comprovante.btnSave = true
            this.comprovante.btnTryAgain = false
            this.comprovante.autoPrint = true

            // this.ServiceFormModal('Sucesso', obj_response.msg)

            this.$bvModal.show('ComprovanteModal')
          } else if (obj_response.status == false) {
            this.comprovante.msg = obj_response.msg
            this.comprovante.TitleModal = 'Transação não realizada'
            this.comprovante.btnSave = false
            this.comprovante.btnTryAgain = true
            // this.ServiceFormModal('Sucesso', obj_response.msg)
            this.$bvModal.show('ComprovanteModal')
          } else {
            this.comprovante.msg = response.data
            this.comprovante.TitleModal = 'Atenção'
            this.comprovante.btnSave = false
            this.comprovante.btnTryAgain = true

            // this.ServiceFormModal('Sucesso', obj_response.msg)

            this.$bvModal.show('ComprovanteModal')
          }

          // this.$router.push({ path: '/Menu' })
        })
        .catch(() => {
          this.$bvModal.hide('LoadingModal')

          // this.info = error;
          // this.modalMsg =  this.info
          // this.$bvModal.show('modal-1')
          return false
        })
    },
    ServiceFormModal: function (title, text) {
      if (title) {
        this.Modal_Title = title
      }
      if (text) {
        this.Modal_Text = text
      }
      this.$bvModal.show('ServiceFormModal')
    },
    inputQRcode: function (id) {
      document.querySelector('#' + id).click()
    },
    onDecode: function (e, index) {
      if (e.match(/^\d+/) !== null) {
        this.qrcode = e
        this.qrCodeReading = false
        this.obj_submit[index] = e
        this.flag_changes++
      } else {
        this.qrCodeReading = false
        this.qrCodeReadingFeedBack = 'QRcode Inválido, Procurando . . .'
      }
    },
    qrCodeRead: function (index) {
      if (typeof window.Android === 'undefined') {
        this.qrCodeReading = true
        this.qrCodeReadingFeedBack = 'Procurando QRcode . . .'
      } else {
        // this.Android_Qrcode(index)
        window.Android.showToast('Leia o QR CODE')
        this.onDecode(window.Android.shotQrCode(), index)
      }
    },
    // ,
    // Android_Qrcode: async function () {
    // }
  },
  beforeCreate() {
    if (typeof this.$store.state.Data.pos_struct == 'undefined') {
      this.$router.push({ path: '/Menu' })
      return false
    }
  },
  created() {
    if (typeof this.service == 'undefined') {
      this.$router.push({ path: '/Menu' })
      return false
    }
    if (this.service.length < 1) {
      this.$router.push({ path: '/Menu' })
      return false
    }

    var steppers = {}
    var steppers_count = 0
    Object.entries(
      this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[
        this.option
      ].form.inputs
    ).forEach(([a, b]) => {
      if (b.type == 'hidden') {
        this.obj_submit[a] = b.value
      } else {
        this.obj_submit[a] = ''
        steppers_count++
        steppers[a] = steppers_count
      }
    })
    this.steppers = steppers
  },
  mounted() {
    // this.$bvModal.show('QRcodeModal')
  },
  computed: {
    isAndroid: () => window.Android,
    computed_form: function () {
      var retorno = {}
      Object.entries(
        this.$store.state.Data.pos_struct.menu_principal[this.service].sub_menu[
          this.option
        ].form.inputs
      ).forEach(([k, v]) => {
        if (typeof v.tipo_usuario !== 'undefined') {
          if (v.tipo_usuario === this.$store.state.Data.tipo_usuario) {
            retorno[k] = v
          }
        } else {
          retorno[k] = v
        }
      })

      return retorno
    },
  },
}
</script>

<style>
.ServiceForm input,
.ServiceForm textarea {
  background: none;
  color: black;
  border: none;
  border-bottom: 1px solid darkslategrey;
}
.ServiceForm input:focus,
.ServiceForm textarea:focus {
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}

.ServiceForm.dark input,
.ServiceForm.dark textarea {
  background: none;
  color: white;
  border: none;
  border-bottom: 1px solid lightgray;
}
.ServiceForm.dark input:focus,
.ServiceForm.dark textarea:focus {
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
}
.select-dark {
  background: black !important;
  color: white !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid white !important;
  text-align: center !important;
  text-align-last: center;
}
.select-dark option {
  background: black;
  color: white;
  border: none;
  border-radius: 0;
  text-align: center;
}

.select-white {
  background: #d3d3d3 !important;
  color: black !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid black !important;
  text-align: center !important;
  text-align-last: center;
}
.select-white option {
  background: #d3d3d3;
  color: black;
  border: none;
  border-radius: 0;
  text-align: center;
}
#services {
  margin-bottom: 100px;
}
</style>
