<template>
  <div :class="painel_class + ' main'" id="app">
    <div style="width:100vw; text-align: center">
      <img
        class="top"
        :src="require('@/assets/TOP.png')"
      >
    </div>
   
    <b-container style="flex: 1;">
      <transition name="fade" mode="out-in">  
        <router-view :painel_class="'painel_'+this.$store.state.themeMode.mode" :services="menu_principal" :flag_changes="flag_changes"></router-view>
      </transition>
    </b-container>
    <div v-if="isMenu" style="align-self: flex-end; width: 100%;">
      <b-navbar small toggleable="lg" :type="this.$store.state.themeMode.mode" :variant="this.$store.state.themeMode.mode">
        <b-navbar-brand href="#" class="">
          SAC 0800 123 1313
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
          
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav  style="bottom:56px;position: absolute;background-color:#fff; width: 100%; left: 0;">
          <b-navbar-nav class="ml-auto">
            <!-- <b-nav-item href="#">Suporte</b-nav-item> -->
            <b-nav-item @click="printTeste">Teste de impressão</b-nav-item>
            <b-nav-item @click="reload">Reiniciar App</b-nav-item>
            <b-nav-item @click="Logout">Sair</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <LoadingModal  
      :bodyBgVariant="this.$store.state.themeMode.mode" 
      :bodyTextVariant="this.$store.state.themeMode.text" 
      :altText="modalMsg"
    />
  </div>
</template>

<script>
import LoadingModal  from './loading-modal.vue'
import axios from 'axios'
import { printTeste } from '../assets/printTest'
export default {
  name: 'App',
  components: {
    LoadingModal,
  },
  data(){
    return{
      loading : false,
      modalMsg:'',
      menu_principal:{},
      bodyBgVariant: this.$store.state.themeMode.mode,
      bodyTextVariant: this.$store.state.themeMode.text,
      imgService: {},
      flag_changes: 0
    }
  },
  props:{
    painel_class : null
  },
  methods : {
    printTeste,
    Logout : function () {
      this.$store.commit("setAuthenticationToken", "")
      this.$store.commit("resetData", "")
      this.$router.push({ path: '/login' })
    },
    isMenu: function () {
      return window.location.href.match(/.#\/Menu/) !== null
    },
    reload: function () {
      window.location.reload()
    }
  }, 
  watch: {
    bodyBgVariant: function () {
      this.flag_changes++
    },
    bodyTextVariant:function() {
      this.flag_changes++
    }
  },
  mounted : function() {
    this.$bvModal.show('LoadingModal')
    axios.get(this.$store.state.server_path+this.$store.state.jsonFileName)
    .then(response => {
      window.console.log(this.$store.state)
      this.$store.commit("addData", {pos_struct : response.data.struct})
      this.menu_principal = response.data.struct.menu_principal
      this.$bvModal.hide('LoadingModal')
    })
    .catch( (error) => {
      window.console.log(error)
      this.modalMsg =  'error'
      return false 
    });
  }
}
</script>

<style>
.main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.painel_light{
  background-color: lightgray;
}
.painel_dark{
  background-color: black;
}
#app{min-height: 100vh;}
.img-services{
  max-width: 100%;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity 0.3s ease;
}

.fade-leave-active {
    transition: opacity 0.3s ease;
    opacity: 0;
}
.top {
  margin: auto;
  width: 100vw;
  max-width: 1000px;
  padding-top: 15px; 
  padding-bottom: 15px; 
}
</style>
